import { debounce } from "lodash";
import { useState, RefObject, useCallback, useEffect } from "react";

interface Config {
  cache?: { width?: number; height?: number };
  externalRefObject?: RefObject<HTMLDivElement>;
  externalRef?: HTMLDivElement;
}

const useComponentDimensions = (
  config?: Config
): {
  width: number;
  height: number;
  measure(el: HTMLDivElement): void;
} => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  const { cache } = config || {};

  const [node, setNode] = useState<HTMLDivElement | null>(null);

  const [dimensions, setDimensions] = useState({
    width: cache?.width || 0,
    height: cache?.height || 0
  });

  const measure = useCallback(
    debounce((el: HTMLDivElement) => {
      if (el) {
        const { clientWidth, clientHeight } = el;

        if (clientWidth || clientHeight)
          setDimensions({ width: clientWidth, height: clientHeight });

        setNode(el);
      }
    }, 0),
    []
  );

  useEffect(() => {
    const measureCurrentNode = () => {
      if (node) measure(node);
    };

    measureCurrentNode();

    node?.addEventListener("resize", measureCurrentNode);

    return () => node?.removeEventListener("resize", measureCurrentNode);
  }, [node, mounted]);

  return { ...dimensions, measure };
};

export default useComponentDimensions;
