import React from "react";

import text from "constants/text";
import Footer from "components/Footer";
import Logo from "../../../../public/full-logo-primary.svg";

const HummingFooter = () => {
  const { footer } = text;

  return <Footer Logo={Logo} {...footer} />;
};

export default HummingFooter;
