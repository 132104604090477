import NavBar from "components/NavBar";
import React, { FC } from "react";

import text from "constants/text";

import Logo from "../../../../public/full-logo-primary.svg";

interface Props {
  withAnnouncement?: boolean;
  isVendorCta?: boolean;
}

const HummingNav: FC<Props> = ({
  withAnnouncement = true,
  isVendorCta = false
}) => {
  return (
    // <NavBar
    //   announcement={
    //     withAnnouncement
    //       ? "Featured in Business Insider's List of Most Promising Consumer Technology Companies! 🎉"
    //       : ""
    //   }
    //   linkAnnouncement="https://www.businessinsider.com/most-promising-consumer-tech-startups-of-2022-8"
    //   Logo={Logo}
    //   options={options}
    //   cta={isVendorCta ? vendorCta : cta}
    // />
    null
  );
};

HummingNav.defaultProps = {
  withAnnouncement: true
};

export default HummingNav;
