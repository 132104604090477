import Label from "components/Label";
import React, { ElementType, FC } from "react";

import Link from "components/Link";
import styles from "./styles.module.scss";

interface Redirect {
  name: string;
  to: string;
}
interface Props {
  Logo: ElementType;
  contact_email: string;
  redirects: { title: string; options: Redirect[] }[];
  copyright: string;
  legal_redirects: Redirect[];
}

const Footer: FC<Props> = ({
  Logo,
  contact_email,
  redirects,
  copyright,
  legal_redirects: legalRedirects
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.top}>
        <div>
          <Logo className={styles.logo} />
          <Label
            className={styles.label}
            variant="s-capitalized"
            color="supplemental"
          >
            {contact_email}
          </Label>
        </div>
        <div className={styles.redirects}>
          {redirects.map(({ title, options }) => (
            <div className={styles.redirectCategory} key={title}>
              <Label className={styles.label} variant="capitalized-s">
                {title}
              </Label>
              <div className={styles.redirectOptions}>
                {options.map(({ name, to }) => (
                  <Link
                    labelClassName={styles.label}
                    variant="s"
                    to={to}
                    label={name}
                    key={name}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className={styles.bottom}>
        <Label
          className={styles.copyright}
          variant="capitalized-s"
          color="supplemental"
        >
          {copyright}
        </Label>
        <div className={styles.bottomActions}>
          {legalRedirects.map(({ name, to }) => (
            <Link
              key={name}
              variant="s"
              to={to}
              label={name}
              labelClassName={styles.legalLabel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Footer;
